<template>
    <el-card class="box-card" v-loading="!vat">
        <div slot="header" class="clearfix dashboard-card-title">
            <svg-icon icon-class="coins" class="dashboard-icon-card" />
            <span class="head-title">{{ $t('dashboard.right.payout-title') }}</span>
        </div>
        <div class="card-body">
            <div>{{ $t('dashboard.right.payout-description') }} {{ date }}</div>

            <div class="separator"></div>

            <div class="title">
                <span class="text-left">{{ $t('dashboard.right.payout-cui') }}</span>
                <span class="float-right">{{ $t('dashboard.right.payout-value') }}</span>
            </div>

            <div>
                <span class="text-left">{{ vat }}</span>
                <span class="float-right">{{ payout }}</span>
            </div>

            <p>
                <router-link :to="{ name: 'FinanciarPlati' }">
                    <el-button plain class="fullWidth b-r-eb" type="primary">{{ $t('general.details') }}</el-button>
                </router-link>
            </p>
        </div>
    </el-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'PageDashboardPayout',
    computed: {
        ...mapState({
            vat: (state) => state.dashboard.centralizator.payouts.vat,
            payout: (state) => state.dashboard.centralizator.payouts.payout,
            date: (state) => state.dashboard.centralizator.payouts.date,
        }),
    },
    methods: {
        async getPayoutEstimates() {
            try {
                await this.$store.dispatch('dashboard/GetPayoutEstimate');
            } catch (e) {
                console.log(e);
            }
        },
        // GetPayments: call('financial/GetPayments'),
    },
    mounted() {
        this.getPayoutEstimates();
        // this.GetPayments();
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.dashboard-icon-card {
		font-size: 27px !important;
		vertical-align: middle;
		color: #ff601d;
	}

	.head-title {
		vertical-align: middle;
		font-weight: 500;
		margin-left: 3px;
	}

	.card-body {
		font-size: 13px;
	}

    .card-body .title {
        font-size: 14px;
        margin-bottom: 10px;
    }
</style>